<template>
  <iframe allowfullscreen class="divHeight" frameborder="0" :src="url" style="border: 0"></iframe>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.divHeight {
  height: 92vh;
  width: 100%;
}
</style>
